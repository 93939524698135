<template>
  <div class="art width_1200">
    <!-- <div>
      <router-link to="/art/artists">艺术家</router-link
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <router-link to="/art/artistRegister">艺术家入驻</router-link
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <router-link to="/art/artworkAdd">艺术品发布</router-link
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <router-link to="/art/artistIntroduction">个人</router-link>
    </div> -->
    <div class="art_search">
      <el-input
        placeholder="搜作品/找艺术家"
        v-model="search"
        @keyup.enter.native="searchClcik()"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <el-button slot="append" @click="searchClcik()">搜索</el-button>
      </el-input>
    </div>
    <el-carousel class="" trigger="click" height="300px">
      <el-carousel-item v-for="item in banners" :key="item.Id">
        <img v-lazy="item.ImgUrl" :alt="item.Name" height="300" width="100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="tabs">
      <ul>
        <li v-for="(t, i) in tabs" :key="i" @click="handleGoto(t, i)">
          <div class="li" v-if="i != 1 && i != 2">
            <img :src="t.img" :alt="t.value" srcset="" />
            <p>{{ t.value }}</p>
          </div>
          <div
            class="li"
            v-if="
              i == 1 &&
              (artInfo.Id == undefined ||
                artInfo.Status == -1 ||
                artInfo.Status == 0)
            "
          >
            <img :src="t.img" :alt="t.value" srcset="" />
            <p>{{ t.value }}</p>
          </div>
          <div
            class="li"
            v-if="i == 2 && artInfo != null && artInfo.Status == 1"
          >
            <img :src="t.img" :alt="t.value" srcset="" />
            <p>{{ t.value }}</p>
          </div>
        </li>
      </ul>
    </div>
    <h3 class="art_title">最新入驻</h3>
    <div class="signContract">
      <ul>
        <li
          class="hover_shadow"
          :span="6"
          v-for="(s, i) in signContractList"
          :key="i"
        >
          <div class="head">
            <router-link
              :to="{
                path: '/teacher/teacherDetails',
                query: {
                  id: s.ArtistId,
                  type: 3,
                },
              }"
            >
              <img v-lazy="s.HeadImg" alt="" srcset="" />
              {{ s.ArtistName }}
            </router-link>
          </div>
          <div class="imgs">
            <img
              v-lazy="a.CoverImg"
              v-for="(a, j) in s.ArtWorks.slice(0, 3)"
              :key="j"
              @click="artClick(a.Id)"
              alt=""
              srcset=""
            />
          </div>
        </li>
      </ul>
    </div>
    <div v-if="signContractList.length <= 0">
      <no-data></no-data>
    </div>
    <h3 class="art_title">最新发布</h3>
    <div class="artList" v-if="artList.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getQueryNewestArtExhibition"
      >
        <ul>
          <li v-for="(a, i) in artList" :key="i" @click="artClick(a.ArtId)">
            <img v-lazy="a.ArtImg" alt="" srcset="" />
            <p class="decimalPoint-2">{{ a.ArtTitle }}</p>
            <div class="head">
              <router-link
                :to="{
                  path: '/teacher/teacherDetails',
                  query: {
                    id: a.ArtistId,
                    type: 3,
                  },
                }"
              >
                <img v-lazy="a.ArtistHeadImg" alt="" srcset="" />{{
                  a.ArtistName
                }}
              </router-link>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
    <div v-if="artList.length <= 0">
      <no-data></no-data>
    </div>
  </div>
</template>

<script>
import noData from "@/components/noData";
import {
  queryBanners,
  queryNewestArtist,
  queryNewestArtExhibition,
  querySupplierByStudentId,
} from "@/api/art";
export default {
  name: "index",
  components: {
    noData,
  },
  computed: {},
  watch: {},
  data () {
    return {
      tabs: [
        {
          img: require("@/assets/images/icon_ysj.png"),
          value: "艺术家",
          path: "/art/artists",
        },
        {
          img: require("@/assets/images/icon_ysjrz.png"),
          value: "艺术家入驻",
          path: "/art/artistRegister",
        },
        {
          img: require("@/assets/images/icon_ysjfb.png"),
          value: "艺术品发布",
          path: "/art/artworkAdd",
        },
        {
          img: require("@/assets/images/icon_grzx.png"),
          value: "个人中心",
          path: "/art/artistIntroduction",
        },
      ],
      search: "",
      banners: [],
      signContractList: [],
      artList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false,
      userInfo: {},
      artInfo: {}
    };
  },
  created () {
    this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    if (this.userInfo) {
      this.getQuerySupplierByStudentId()
    }
    this.init()
    this.getQueryNewestArtExhibition()
    console.log(this.artInfo, '')
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeDestroy () {
  },
  methods: {
    handleGoto (t, i) {
      let path = t.path;
      if (i != 0) {
        // this.$message.error("功能开发中，敬请期待！");
        // return false
        if (!this.userInfo.Id) {
          this.$message.error("请登录")
          return false;
        }
        // Status 0 未通过 1审核通过
        if ((i == 2 || i == 3) && this.artInfo.Status != 1) {
          // Dialog.alert({
          //   message: "入驻信息审核中，请耐心等待",
          //   theme: "round-button",
          //   confirmButtonColor: "#ee0a24",
          // }).then(() => {
          //   // on close
          // });
          this.$message.error("入驻信息审核中，请耐心等待");
          return false;
        }
        if (i == 1) {
          path = path +
            "?UserID=" +
            this.userInfo.Id +
            "&artistId=" +
            this.artInfo.Id;
        }
        if (i == 2) {
          path = path + "?UserID=" + this.userInfo.Id;
        }
        if (i == 3) {
          path = path + "?id=" + this.artInfo.Id;
        }
      }
      this.$router.push(path);
    },
    // 根据学生ID获取艺术家详情
    async getQuerySupplierByStudentId () {
      // console.log(this.userInfo, '')
      let studentId = "?studentId=" + this.userInfo.Id; // this.route.UserId
      const res = await querySupplierByStudentId(studentId);
      this.artInfo = res.data.response ? res.data.response : {};
      localStorage.setItem("artInfo", JSON.stringify(this.artInfo));
    },
    artClick (id) {
      // let url = "/PCshop/goods/goodsdetail"
      // let ob = {
      //   id: id,
      // }
      let url = "/art/artistsDetails"
      let ob = {
        id: id,
        artUserId: this.userInfo.Id
      }
      this.$router.push({ path: url, query: ob });
    },
    searchClcik () {
      this.$router.push({
        name: `art/list`,
        params: { keyWords: this.search }
      })
    },
    async getQueryNewestArtExhibition () {
      let parm = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }
      const res = await queryNewestArtExhibition(parm);
      this.artList = this.artList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.artList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    async init () {
      let parm = {
        pageIndex: 1,
        pageSize: 10,
        category: 4//	4：艺术展PC，5：艺术展APP
      }
      const res = await queryBanners(parm);
      if (res.success == true) {
        this.banners = res.response.data;
      }
      let parm1 = {
        pageIndex: 1,
        pageSize: 4,
      }
      const res1 = await queryNewestArtist(parm1);
      if (res1.success == true) {
        this.signContractList = res1.response.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.art {
  .tabs {
    margin-top: 40px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        .li {
          width: 180px;
          height: 88px;
          background: #ffffff;
          border: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          margin-right: 30px;
          img {
            width: 68px;
            height: 68px;
            margin: 0 4px 0 16px;
          }
          &:hover {
            opacity: 0.8;
            border-color: #4887f9;
            box-shadow: -2px 4px 8px rgba(72, 135, 249, 0.2);
          }
        }
      }
    }
  }
  .art_search {
    width: 520px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #4887f9;
    box-shadow: 3px 3px 6px rgba(72, 135, 249, 0.16);
    opacity: 1;
    border-radius: 20px;
    margin: 40px auto;
    // overflow: hidden;
    padding: 4px;
    /deep/.el-input {
      .el-input__inner {
        height: 32px;
        border: none;
        background-color: transparent;
      }
      .el-input__icon {
        line-height: 32px;
      }
      .el-input-group__append {
        width: 70px;
        height: 32px;
        color: #ffffff;
        background: #4887f9;
        border-radius: 40px;
      }
    }
  }
  .art_title {
    width: 340px;
    height: 98px;
    line-height: 98px;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    margin: 60px auto 50px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/categ-title.png");
  }
  .signContract {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;
      li {
        flex: 0 0 585px;
        width: 585px;
        height: 286px;
        padding: 30px;
        margin: 0 0 30px 30px;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        // box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
        .head {
          img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
          }
          font-size: 18px;
          font-weight: bold;
        }
        .imgs {
          img {
            width: 156px;
            height: 156px;
            margin-left: 27px;
            margin-top: 25px;
            border-radius: 2px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .artList {
    ul {
      display: inline-block;
      -moz-column-count: 3; /* Firefox */
      -webkit-column-count: 3; /* Safari 和 Chrome */
      column-count: 3;
      -moz-column-gap: 30px;
      -webkit-column-gap: 30px;
      column-gap: 30px;
      margin-bottom: 30px;
      li {
        break-inside: avoid;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        margin-bottom: 30px;
        cursor: pointer;
        img {
          width: 100%;
          border-radius: 6px;
        }
        p {
          font-size: 18px;
          font-weight: 800;
          line-height: 1;
          margin: 20px 20px 10px;
        }
        .head {
          margin: 0 20px 20px;
          img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
